var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "manager--content",
      class: {
        centerY: !_vm.nodeHealthcheckData || !_vm.nodeHealthcheckData.length,
      },
    },
    [
      _c(
        "div",
        { staticClass: "bg-grey h-100" },
        [
          _vm.isFetchingData
            ? _c("loading-overlay")
            : _vm.nodeHealthcheckData && _vm.nodeHealthcheckData.length
            ? _c(
                "div",
                { staticClass: "health-check" },
                [
                  _vm.nodeHealthcheckData && _vm.nodeHealthcheckData.length
                    ? _c("health-check-table", {
                        attrs: {
                          headers: _vm.headers,
                          "health-check-data": _vm.nodeHealthcheckData,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c("div", { staticClass: "no-manager-data flex no-data" }, [
                _c("div", [
                  _c("p", { staticClass: "message-text" }, [
                    _vm._v(
                      " You currently have no investments in your setup. Please upload a file or update Manager Settings "
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "flex flex--row-space-around mt-3" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex flex--column column-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "theme-icon flex flex--row-center",
                              attrs: { title: "Manager Settings" },
                              on: { click: _vm.goToManagerSettings },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "user-cog"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" Manager Settings "),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex--column column-center" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "theme-icon flex flex--row-center",
                              attrs: { title: "Upload excel file" },
                              on: { click: _vm.goToBulkOperations },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["fas", "file-excel"] },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" Upload file "),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }