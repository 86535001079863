

































import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { Component, Vue } from 'vue-property-decorator';

import HealthCheckTable from '@/components/MonitorManager/HealthCheckTable.vue';
import { IHealthCheckData } from '@/interfaces/IHealthCheckData';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import TableHeader from '@/models/TableHeader';
import healthCheckApi from '@/api/healthCheckApi';

dayjs.extend(minMax);

@Component({
    components: {
        HealthCheckTable,
        LoadingOverlay,
    },
})

export default class HealthCheck extends Vue {
    public goToManagerSettings (): void {
        this.$router.push('/manager-settings');
    }

    public goToBulkOperations (): void {
        this.$router.push('/bulk-operations');
    }

    nodeHealthcheckData: Array<IHealthCheckData> = [];

    get currencySymbol (): string {
        return this.$store.getters.getCurrencySymbol;
    }

    headers: Array<TableHeader> = [
        { key: 'name', label: 'Name' },
        { key: 'id', label: 'ID' },
        { key: 'type', label: 'Type' },
        { key: 'mostRecentMarketValue', label: `Most recent NAV (${this.currencySymbol})` },
        { key: 'mostRecentMarketValueDate', label: 'on' },
        { key: 'earliestMarketValueDate', label: 'Earliest NAV date' },
        { key: 'rollForwardTickersByDate', label: 'Rollforward tickers' },
        { key: 'rollForwardTickersHealth', label: 'OK?' },
        { key: 'benchmarkTickersByDate', label: 'Benchmark tickers' },
        { key: 'benchmarkTickersHealth', label: 'OK?' },
        { key: 'strategicBenchmark', label: 'Strategic benchmark' },
        { key: 'additionalInformation', label: 'Additional information' },
    ];

    created (): void {
        this.fetchData();
    }

    private async fetchData (): Promise<void> {
        this.isFetchingData = true;
        try {
            this.nodeHealthcheckData = await healthCheckApi.getHealthCheckInformation();
        } catch (error) {
            // console.error(error);
        } finally {
            this.isFetchingData = false;
        }
    }


    public isFetchingData = false;

}

