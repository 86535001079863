var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "benchmarks" }, [
    _c(
      "button",
      {
        staticClass: "flex flex--row-space-between full-width",
        on: { click: _vm.toggle },
      },
      [
        _c(
          "span",
          { staticClass: "button-text" },
          [
            _vm._v(" +" + _vm._s(_vm.messages.length) + " "),
            _vm.messages.some(function (m) {
              return m.status !== "success"
            })
              ? _c("font-awesome-icon", {
                  staticClass: "warning-icon",
                  class: _vm.messages.some(function (m) {
                    return m.status === "error"
                  })
                    ? "error"
                    : "warning",
                  attrs: { icon: ["fal", "exclamation-triangle"] },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("font-awesome-icon", {
          staticClass: "icon",
          class: { flipped: _vm.isShown },
          attrs: { icon: ["far", "chevron-down"] },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "messages-wrapper", class: { expanded: _vm.isShown } },
      [
        _c("strong", [_vm._v("Alternative benchmarks:")]),
        _vm._l(_vm.messages, function (message, index) {
          return _c(
            "div",
            { key: index, staticClass: "message" },
            [
              message.status !== "success"
                ? _c("font-awesome-icon", {
                    staticClass: "warning-icon",
                    class: message.status,
                    attrs: { icon: ["fal", "exclamation-triangle"] },
                  })
                : _vm._e(),
              _c("div", { domProps: { innerHTML: _vm._s(message.message) } }),
              index !== _vm.messages.length - 1 ? _c("hr") : _vm._e(),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }