var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "health-check--bg" }, [
    _c(
      "div",
      { staticClass: "table table-striped" },
      [
        _c(
          "div",
          {
            staticClass: "tr thead table-row position-sticky font-weight-bold",
          },
          _vm._l(_vm.headers, function (header, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "td align-center",
                class: _vm.getColWidth(header.key),
              },
              [_c("span", [_vm._v(_vm._s(header.label))])]
            )
          }),
          0
        ),
        _vm._l(_vm.healthCheckTableItems, function (node, index) {
          return _c(
            "div",
            { key: "node" + index, staticClass: "tr table-row tbody" },
            _vm._l(_vm.headers, function (header, itemIndex) {
              return _c(
                "div",
                { key: "header" + itemIndex, staticClass: "td data" },
                [
                  Array.isArray(node[header.key])
                    ? _vm._l(
                        node[header.key],
                        function (headerItem, headerItemIndex) {
                          return _c(
                            "div",
                            { key: headerItemIndex },
                            [
                              headerItem.status !== _vm.TEXT_SUCCESS
                                ? _c("font-awesome-icon", {
                                    staticClass: "warning-icon",
                                    class: headerItem.status,
                                    attrs: {
                                      icon: ["fal", "exclamation-triangle"],
                                    },
                                  })
                                : _vm._e(),
                              _c("span", {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: headerItem.tooltipContent,
                                    expression: "headerItem.tooltipContent",
                                  },
                                ],
                                domProps: {
                                  innerHTML: _vm._s(headerItem.text),
                                },
                              }),
                            ],
                            1
                          )
                        }
                      )
                    : [
                        _vm.getHealthCheckItemDetails(node, header.key)
                          .status !== _vm.TEXT_SUCCESS
                          ? _c("font-awesome-icon", {
                              staticClass: "warning-icon",
                              class: _vm.getHealthCheckItemDetails(
                                node,
                                header.key
                              ).status,
                              attrs: { icon: ["fal", "exclamation-triangle"] },
                            })
                          : _vm._e(),
                        _c("span", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.getHealthCheckItemDetails(
                                node,
                                header.key
                              ).tooltipContent,
                              expression:
                                "getHealthCheckItemDetails(node, header.key).tooltipContent",
                            },
                          ],
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.getHealthCheckItemDetails(node, header.key)
                                .text
                            ),
                          },
                        }),
                      ],
                  _vm.indexedBenchmarkHealthMessages[index] &&
                  header.key === "benchmarkTickersByDate"
                    ? _c("secondary-benchmarks", {
                        attrs: {
                          messages:
                            _vm.indexedBenchmarkHealthMessages[index].tickers,
                          "is-shown":
                            _vm.indexedBenchmarkHealthMessages[index].show,
                        },
                        on: {
                          toggle: function ($event) {
                            return _vm.toggleSecondaryBenchmarkTooltip(index)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.indexedBenchmarkHealthMessages[index] &&
                  header.key === "benchmarkTickersHealth"
                    ? _c("secondary-benchmarks", {
                        attrs: {
                          messages:
                            _vm.indexedBenchmarkHealthMessages[index]
                              .tickersHealth,
                          "is-shown":
                            _vm.indexedBenchmarkHealthMessages[index].show,
                        },
                        on: {
                          toggle: function ($event) {
                            return _vm.toggleSecondaryBenchmarkTooltip(index)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                2
              )
            }),
            0
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }