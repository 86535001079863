import { TTableItemStatus } from '@/types/TTableItemStatus';

export default class TableItemDetails {
    text: string;

    status: TTableItemStatus;

    tooltipContent: string;

    constructor (text = '', status: TTableItemStatus = 'success', tooltipContent = '') {
        this.text = text;
        this.status = status;
        this.tooltipContent = tooltipContent;
    }
}
