





















































import dayjs from 'dayjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { MessageType } from '@/enums/MessageType';
import { TTableItemStatus } from '@/types/TTableItemStatus';
import { formatNumber } from '@/utils';
import { IHealthCheckColumnInfo } from '@/interfaces/IHealthCheckColumnInfo';
import { IHealthCheckMessageInfo } from '@/interfaces/IHealthCheckMessageInfo';
import { IHealthCheckData } from '@/interfaces/IHealthCheckData';
import NodeHealthCheckItem from '@/models/NodeHealthCheckItem';
import TableHeader from '@/models/TableHeader';
import TableItemDetails from '@/models/TableItemDetails';
import SecondaryBenchmarks from './SecondaryBenchmarks.vue';
import { ITickerMessageWithStatus } from '@/interfaces/ticker/ITickerMessageWithStatus';

const TICKERS_NOT_SET_WARNING = 'Not set. Fund will not show in the performance tab on Monitor Pending';
const INVALID_TICKER_TOOLTIP = 'Add a valid ticker';
const TICKER_ERRORS_OK = 'OK';
const NAV_NOT_PROVIDED = 'Not provided';
const LINE_BREAK = '<br/><br/>';
const TEXT_NOT_SET = 'Not set';
const TEXT_SUCCESS = 'success';
const TEXT_ERROR = 'error';
const TEXT_WARNING = 'warning';
const TEXT_NOT_APPLICABLE = 'N/A';

@Component({
    components: { SecondaryBenchmarks },
})

export default class HealthCheckTable extends Vue {
    @Prop({ type: Array, required:true }) headers: Array<TableHeader>;

    @Prop({ type: Array, required: false }) healthCheckData: Array<IHealthCheckData>;

    public healthCheckTableItems: Array<NodeHealthCheckItem> = [];

    public addedRowNo: Array<number> = [];

    public indexedBenchmarkHealthMessages: Record<number, { tickers: Array<ITickerMessageWithStatus>, tickersHealth: Array<ITickerMessageWithStatus>, show: boolean }> = {};

    TEXT_SUCCESS = Object.freeze(TEXT_SUCCESS);

    created (): void {
        if (!this.healthCheckData) return;

        this.healthCheckData.forEach((data, index) => {
            const tableRow = this.getTableRowFromHealthCheckData(data);
            this.healthCheckTableItems.push(tableRow);

            if (data.benchmarkInformation?.secondaryBenchmarksHealth.length) {
                Vue.set(this.indexedBenchmarkHealthMessages, index, {
                    tickers: data.benchmarkInformation.secondaryBenchmarksHealth.map(health => ({
                        message: this.getTickerText(health, true, data.type),
                        status: this.getTickerHealthStatus(health),
                    })),
                    tickersHealth: data.benchmarkInformation.secondaryBenchmarksHealth.map(health => ({
                        message: this.getTickerHealthText(health, data.type),
                        status: this.getTickerHealthStatus(health),
                    })),
                    show: false,
                });
            };
        });
    }

    private getTableRowFromHealthCheckData (data: IHealthCheckData): NodeHealthCheckItem {
        return {
            name: new TableItemDetails(data.name),
            id: new TableItemDetails(data.id.toString()),
            type: new TableItemDetails(data.type),
            mostRecentMarketValue: new TableItemDetails(
                this.getLatestMarketValue(data),
                data.navInformation?.mostRecentNavValue === null ? TEXT_ERROR : TEXT_SUCCESS,
            ),
            mostRecentMarketValueDate: new TableItemDetails(
                this.getMarketValueDate(data),
                this.getMarketValueStatus(data),
                data.navInformation?.isMostRecentNavHistoric ? 'Dates are historic (>3 mo. old)' : '',
            ),
            earliestMarketValueDate: new TableItemDetails(
                this.getEarliestMarketValueDate(data),
            ),
            rollForwardTickersByDate: new TableItemDetails(
                this.getTickerText(data.rollForwardInformation, false, data.type),
                this.getTickerStatus(data.rollForwardInformation),
            ),
            rollForwardTickersHealth: new TableItemDetails(
                this.getTickerHealthText(data.rollForwardInformation, data.type),
                this.getTickerHealthStatus(data.rollForwardInformation),
                data.rollForwardInformation?.isOkColumnMessages.length ? INVALID_TICKER_TOOLTIP: '',
            ),
            benchmarkTickersByDate: new TableItemDetails(
                data.benchmarkInformation ? this.getTickerText(data.benchmarkInformation.primaryBenchmarkHealth, true, data.type) : '',
                this.getTickerHealthStatus(data.benchmarkInformation?.primaryBenchmarkHealth) ?? TEXT_SUCCESS,
                data.benchmarkInformation?.primaryBenchmarkHealth.isOkColumnMessages.length ? INVALID_TICKER_TOOLTIP: '',
            ),
            benchmarkTickersHealth: new TableItemDetails(
                data.benchmarkInformation ? this.getTickerHealthText(data.benchmarkInformation.primaryBenchmarkHealth, data.type) : '',
                this.getTickerHealthStatus(data.benchmarkInformation?.primaryBenchmarkHealth) ?? TEXT_SUCCESS,
                data.benchmarkInformation?.primaryBenchmarkHealth.isOkColumnMessages.length ? INVALID_TICKER_TOOLTIP: '',
            ),
            strategicBenchmark: new TableItemDetails(data.strategicAllocations || TEXT_NOT_SET),
            additionalInformation: data.additionalInformation && data.additionalInformation.length ?
                data.additionalInformation.map(
                    info => new TableItemDetails(this.getAdditionalInformationText(info), this.getAdditionalInformationStatus(info)),
                ) : [
                    new TableItemDetails('', 'success', '')
                ],

        };
    }

    private getEarliestMarketValueDate (data: IHealthCheckData): string {
        if (this.checkIfTypeIsGrouping(data.type)) return TEXT_NOT_APPLICABLE;
        return data.navInformation && data.navInformation.earliestNavDate ? dayjs(data.navInformation.earliestNavDate).format('D/M/YYYY') : NAV_NOT_PROVIDED;
    }

    private getLatestMarketValue (data: IHealthCheckData): string {
        if (this.checkIfTypeIsGrouping(data.type)) return TEXT_NOT_APPLICABLE;
        return data.navInformation && data.navInformation?.mostRecentNavValue !== null ? formatNumber(data.navInformation?.mostRecentNavValue, 2, '.', ',', this.$store.getters.getCurrencySymbol ) : NAV_NOT_PROVIDED;
    }

    private getMarketValueStatus (data: IHealthCheckData): TTableItemStatus {
        return data.navInformation?.isMostRecentNavHistoric && data.navInformation?.mostRecentNavValue !== null ? TEXT_WARNING : TEXT_SUCCESS;
    }

    private getMarketValueDate (data: IHealthCheckData): string {
        if (this.checkIfTypeIsGrouping(data.type)) return TEXT_NOT_APPLICABLE;
        if (data.navInformation?.mostRecentNavValue === undefined) return NAV_NOT_PROVIDED;
        return data.navInformation && data.navInformation.mostRecentNavDate ? dayjs(data.navInformation.mostRecentNavDate).format('D/M/YYYY') : '';
    }

    private getTickerText (info: IHealthCheckColumnInfo | null, isBenchmark: boolean, dataType: string): string {
        if (!info) {
            if (!isBenchmark && this.checkIfTypeIsGrouping(dataType)) return TEXT_NOT_APPLICABLE;
            return isBenchmark ? TEXT_NOT_SET : TICKERS_NOT_SET_WARNING;
        }
        if (info.tickersColumnString.length) return this.getBenchmarkNameBold(info.benchmarkName) + LINE_BREAK + info.tickersColumnString.join(LINE_BREAK);
        return isBenchmark ? TEXT_NOT_SET : TICKERS_NOT_SET_WARNING;
    }

    private getTickerStatus (info: IHealthCheckColumnInfo | null): TTableItemStatus {
        if (!info) return TEXT_SUCCESS;
        if (info.tickersColumnString.length) return TEXT_SUCCESS;
        return TEXT_WARNING;
    }

    private getTickerHealthText (info: IHealthCheckColumnInfo | null, dataType: string): string {

        if (!info) {
            if (this.checkIfTypeIsGrouping(dataType)) return TEXT_NOT_APPLICABLE;
            return TEXT_NOT_SET;
        }

        const benchmarkName = this.getBenchmarkNameBold(info.benchmarkName);

        if (info.isOkColumnMessages.length) {
            const messagesSortedByStatus = info.isOkColumnMessages.sort((a, b) => a.messageType - b.messageType);
            return benchmarkName + LINE_BREAK + messagesSortedByStatus.map(m => m.messageContent).join(LINE_BREAK);
        };

        return benchmarkName + LINE_BREAK + TICKER_ERRORS_OK;
    }

    private getAdditionalInformationText (info: IHealthCheckMessageInfo | null): string {
        if (!info) return '';
        return info.messageContent;
    }

    private getAdditionalInformationStatus (info: IHealthCheckMessageInfo | null): TTableItemStatus {
        if (!info) return TEXT_SUCCESS;

        if (info.messageType === MessageType.Error) return TEXT_ERROR;
        if (info.messageType === MessageType.Warning) return TEXT_WARNING;

        return TEXT_SUCCESS;
    }

    private getBenchmarkNameBold (benchmarkName: string): string {
        return '<b>' + benchmarkName + '</b>';
    }

    private getTickerHealthStatus (info: IHealthCheckColumnInfo | null): TTableItemStatus {
        if (!info) return TEXT_SUCCESS;

        const messageTypes = info.isOkColumnMessages.map(m => m.messageType);

        if (messageTypes.includes(MessageType.Error)) return TEXT_ERROR;
        if (messageTypes.includes(MessageType.Warning)) return TEXT_WARNING;

        return TEXT_SUCCESS;
    }

    private checkIfTypeIsGrouping (type: string): boolean {
        const validTypesForGrouping = ['Portfolio', 'Investment Grouping'];
        return validTypesForGrouping.includes(type);
    }

    getHealthCheckItemDetails (item: NodeHealthCheckItem, key: TableHeader['key']): TableItemDetails {
        return item[key] as TableItemDetails;
    }

    getColWidth (name: string): string {
        if (name === 'name') {
            return 'th-name';
        }

        if (name === 'mostRecentMarketValueDate') {
            return 'w-100px';
        }

        return '';
    }

    toggleSecondaryBenchmarkTooltip (index: number): void {
        Vue.set(this.indexedBenchmarkHealthMessages[index], 'show', !this.indexedBenchmarkHealthMessages[index].show);
    }
}

