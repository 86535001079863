































import { ITickerMessageWithStatus } from '@/interfaces/ticker/ITickerMessageWithStatus';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SecondaryBenchmarks extends Vue {
    @Prop({ type: Array, required: true }) messages: Array<ITickerMessageWithStatus>;

    @Prop({ type: Boolean, required: true }) isShown: boolean;

    get messageTexts (): string {
        return this.messages.map(m => m.message).join('<hr/>');
    }

    toggle (): void {
        this.$emit('toggle');
    }
}

